@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
  }
  
  .fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50; /* Ensure the navbar is on top of other elements */
  }
