.font-MoglanDemo {
    @apply font-moglanDemo;
}

/* .font-GoldenBird {
    @apply font-goldenBird;
} */

.custom-bg-size {
    background-size: cover;
}

.custom-bg-position {
    background-position: center center;
}

.fullscreen-bg {
    height: 100vh; /* Default height for large screens */
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
}

.bio-container {
    /* border: 2px solid red;  */
    color: black;
    margin-top: 10%;
    margin-left: 10%;
    font-size: clamp(.6rem, 3vw, 2.5rem);
    font-family: 'MoglanDemo', sans-serif;
    max-width: 40%;
}

/* Adjustments for smaller screens */
@media (max-width: 1670px) {
    .custom-bg-position {
        background-position: right;
    }
}

@media (max-width: 1350px) {
    .fullscreen-bg {
        height: 120vh; /* Keep full height for better background visibility */
    }
    .custom-bg-position {
        background-position: 90% center;
    }
}

@media (max-width: 950px) {
    .fullscreen-bg {
        height: 120vh; /* Keep full height for better background visibility */
    }
    .custom-bg-position {
        background-position: 90% center;
    }
}

@media (max-width: 790px) {
    .fullscreen-bg {
        height: 75vh; /* Decrease height for small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
    .custom-bg-size {
        background-size: 150%;
    } 
}
@media (max-width: 710px) {
    .fullscreen-bg {
        height: 70vh; /* Decrease height for small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
    .custom-bg-size {
        background-size: 150%;
    } 
}

@media (max-width: 660px) {
    .fullscreen-bg {
        height: 58vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
    .custom-bg-size {
        background-size: 150%;
    } 
}

@media (max-width: 640px) {
    .fullscreen-bg {
        height: 59vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
    .custom-bg-size {
        background-size: 150%;
    } 
}

@media (max-width: 590px) {
    .fullscreen-bg {
        height: 57vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
    .custom-bg-size {
        background-size: 150%;
    } 
}

@media (max-width: 533px) {
    .fullscreen-bg {
        height: 52vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}

@media (max-width: 490px) {
    .fullscreen-bg {
        height: 52vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 460px) {
    .fullscreen-bg {
        height: 52vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}

@media (max-width: 440px) {
    .fullscreen-bg {
        height: 48vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 420px) {
    .fullscreen-bg {
        height: 48vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 400px) {
    .fullscreen-bg {
        height: 46vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 380px) {
    .fullscreen-bg {
        height: 51vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 360px) {
    .fullscreen-bg {
        height: 46vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 340px) {
    .fullscreen-bg {
        height: 42vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
@media (max-width: 320px) {
    .fullscreen-bg {
        height: 36vh; /* Decrease height for very small screens */
    }
    .custom-bg-position {
        background-position: 95% top;
    }
}
/* Add this media query for increasing bio text size below 550px */
@media (max-width: 550px) {
    .bio-container {
        font-size: .85rem; /* Adjust this value as needed */
    }
}